
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { fromLocal, toLocal } from '@/api/localstore';
import { User } from '@/api/models/user';
import { GeoLoc } from '@/api/models/geo';
import { isNumeric, validDateTimeString, validEmail, validTimeString } from '@/api/validators';
import { fetchGeoLocation } from '@/api/geolocation';
import { fetchPlacenames, getTzData, fetchSubjectData, savePublicUser, fetchPublicUser } from '@/api/methods';
import { notEmptyString } from '@/api/validators';
import { degAsDms, smartCastFloat, hourMinTz, objectToMap, sanitize, zeroPad } from '@/api/converters';
import { defaultGenderOptions } from '@/api/setings';
import { LatLngDisplay, NameObjValue } from '@/api/interfaces';
import { julToDateParts } from '@/api/julian-date';
import { LngPairChartData } from '@/api/models/lng-pair-chart-data';
import { extractText, generateAnonEmail, isAnonUser } from '@/api/helpers';

@Options({
  emits: ['update']
})
export default class PersonForm extends Vue {
  
  @Prop({ default: "p1" }) readonly context: string = "";
  @Prop({ default: false }) readonly showSubmit: boolean = false;

  user = new User();

  showEmail = false;

  nickName = "";

  identifier = "";

  anonIdentifier = "";

  useragent = "";

  geo = new GeoLoc();

  birthGeo = new GeoLoc();

  suggestedLocations: any[] = [];

  placeName = "";

  tempLocMatch = "";

  tzInfo = ""

  gender = '-';

  dobDate = "";

  dobTime = "12:00";

  offsetHrs = '';

  isFetching = false;

  eventType = "birth";

  roddenValue = "200";

  roddenOptions = [];

  eventTypes = [];

  showLocation = false;

  created(): void {
    this.initDob();
    this.showEmail =  this.context === 'main';
    this.offsetHrs = (new Date().getTimezoneOffset() / 60).toString();
    this.emitter.on('save-person', (contextType: string) => {
      if ((contextType === this.context || contextType === 'both') && this.isValid) {
        this.save();
      }
    })
    this.emitter.on('sync-person', (result: any) => {
      if (result instanceof Object) {
        const { context, data } = result;
        if (context === this.context) {
          this.syncPerson(data, context);
        }
      }
    });
    this.emitter.on('pair-loaded', (pair: LngPairChartData) => {
      if (this.context === "main") {
        this.syncPerson(pair.p1, this.context);
      } else if (this.context === "partner") {
        this.syncPerson(pair.p2, this.context);
      }
    })
    this.emitter.on('load-from-email', (email: string) => {
      this.identifier = email;
      this.loadRemote();
    });
    this.emitter.on('load-remote', (ok: boolean) => {
      if (ok && this.hasEmail && this.context === 'main') {
        this.loadRemote();
      }
    });
    this.emitter.on('reset-form', (context: string) => {
      if (context === this.context) {
        this.resetForm();
      }
    });
    this.emitter.on('escape', (ok: boolean) => {
      if (ok) {
        this.showEmail = true;
      }
    });
    this.emitter.on('clear-user-data', (ok: boolean) => {
      if (ok) {
        this.resetForm();
        this.identifier = generateAnonEmail();
      }
    })
  }

  mounted(): void {
    if (this.context === 'main') {
      this.syncMainUser();
    }
    this.sync();
  }

  syncMainUser(): void {
    const stored = fromLocal('publicuser', 52 * 7 * 24 * 60 * 60);
    if (!stored.expired) {
      const { data } = stored;
      if (data instanceof Object) {
        this.user = new User(stored.data);
        this.identifier = this.user.identifier;
        this.nickName = this.user.nickName;
        this.gender = this.user.gender;
        if (validDateTimeString(this.user.dob)) {
          const [date, time] = this.user.dob.split("T");
          if (date) {
            this.dobDate = date;
          }
          if (time) {
            this.dobTime = time;
          }
        }
        if (this.user.geo instanceof GeoLoc) {
          this.geo = this.user.geo;
        }
      }
    }
    const geoStore = fromLocal("current_geo", 3 * 24 * 60 * 60);
    if (!geoStore.expired) {
      this.geo = new GeoLoc(geoStore.data);
      this.user.geo = this.geo;
    }
    this.useragent = navigator.userAgent;
    if (this.accountInfoEnabled && this.identifier.length < 5) {
      this.identifier = generateAnonEmail();
    }
  }

  get hasEmail(): boolean {
    return this.accountInfoEnabled && validEmail(this.identifier);
  }

  get emailLabel(): string {
    return `Load from email address`;
  }

  get emailClasses(): string[] {
    const cls: string[] = [];
    if (this.isAnon) {
      cls.push("anon");
    } else if (!this.hasEmail) {
      cls.push("empty");
    }
    return cls;
  }

  get hasAccountEmail(): boolean {
    
    return this.hasEmail && notEmptyString(this.nickName);
  }

  get cacheKey(): string {
    return ['person', this.context].join('-');
  }

  get geoDisplay(): string {
    return [degAsDms(this.birthGeo.lat, 'lat', 0), degAsDms(this.birthGeo.lng, 'lng', 0)].join(', ');
  }

  get subFormLetter(): string {
    return this.context === 'main' ? 'A' : 'B';
  }

  get legend(): string {
    return ['Chart', this.subFormLetter].join(' ');
  }

  get accountInfoEnabled(): boolean {
    return this.context === "main";
  }

  get offsetTime(): string {
    const minsInt = parseFloat(this.offsetHrs) * 60;
    const isNeg = minsInt < 0;
    const hrs = isNeg ? Math.ceil(minsInt / 60) : Math.floor(minsInt / 60);
    const mins = minsInt % 60;
    return [zeroPad(hrs,2), zeroPad(mins,2)].join(":");
  }

  get locationRowClasses(): string[] {
    const cls = [];
    if (this.hasLocation) {
      cls.push('is-active');
    }
    if (this.showLocation) {
      cls.push('show-info');
    }
    return cls;
  }

  get wrapperClasses(): string[] {
    return [[this.context, 'subsection'].join('-')];
  }

  get offsetHoursTip(): string {
    return `Enter the time zone in decimal hours from UTC, e.g. 5.5 for India or -5 for East Coast, USA`;
  }

  get accountTip(): string {
    return `Enter your email to store your results`;
  }

  get showLoadRemote(): boolean {
    return validEmail(this.identifier);
  }

  get resetTooltip(): string {
    return `Reset this form to create a new pair`;
  }

  toggleLocation(): void {
    if (this.hasLocation) {
      this.showLocation = !this.showLocation;
    } else {
      this.showLocation = false;
    }
  }

  toggleEmail(): void {
    if (this.accountInfoEnabled) {
      this.showEmail = !this.showEmail;
    } else {
      this.showEmail = false;
    }
  }

  sync(): void {
    const stored = fromLocal(this.cacheKey, (7 * 24 * 60 * 60));
    if (!stored.expired) {
      const mp = objectToMap(stored.data);
      if (mp.has('geo')) {
        this.birthGeo = new GeoLoc(mp.get('geo'));
      }
      if (mp.has('name')) {
        this.nickName = mp.get('name');
      }
      if (mp.has('dob')) {
        const dob = mp.get('dob');
        if (typeof dob === 'string') {
          const dtStr = dob.split('.').shift();
          if (typeof dtStr === 'string') {
            const [dateStr, timeStr] = dtStr.split('T');
            this.dobDate = dateStr;
            this.dobTime = timeStr;
          }
        }
      }
      if (mp.has('tzOffset')) {
        const tzStr = mp.get('tzOffset');
        this.offsetHrs = (smartCastFloat(tzStr) / (60 * 60)).toString();
      }
      if (mp.has('tzInfo')) {
        const tzStr = mp.get('tzInfo');
        if (notEmptyString(tzStr)) {
          this.tzInfo = tzStr;
        }
      }
      if (mp.has('placeName')) {
        this.placeName = mp.get('placeName');
      }
      if (mp.has('roddenValue')) {
        this.roddenValue = mp.get('roddenValue');
      }
      if (mp.has('gender')) {
        this.gender = mp.get('gender');
      }
      if (mp.has('eventType')) {
        this.eventType = mp.get('eventType');
      }
      const st2 = fromLocal('publicuser');
      if (st2.valid) {
          const { data } = st2;
          const puid = data._id;
          if (notEmptyString(puid)) {
            const payload = {
              subject: this.subject,
              identifier: this.identifier,
              geo: this.birthGeo,
              context: this.context,
              puid
            }
            this.$emit('update', payload);
            toLocal('publicuser', data);
          }
      }
    }
  }

  saveDisabled(): boolean {
    return !this.isValid;
  }

  get isValid(): boolean {
    return notEmptyString(this.nickName) && validDateTimeString(this.dob) && this.hasLocation;
  }

  get dob(): string {
    const tStr = validTimeString(this.dobTime) ? this.dobTime : "12:00";
    const timeParts = tStr.split(":");
    if (timeParts.length < 3) {
      timeParts.push("00")
    }
    const timeStr = timeParts.join(":")
    return [this.dobDate, timeStr].join("T");
  }

  get hasLocation(): boolean  {
    return this.birthGeo.lat !== 0 || this.birthGeo.lng !== 0;
  }

  get dmsLocation(): LatLngDisplay {
    return {
      lat: degAsDms(this.birthGeo.lat, "lat", 0),
      lng: degAsDms(this.birthGeo.lng, "lng", 0)
    };
  }

  get genderOpts(): any[] {
    return defaultGenderOptions;
  }

  get subject(): any {
    return {
      name: this.nickName,
      dob: this.dob,
      tzOffset: parseFloat(this.offsetHrs) * 60 * 60,
      tzInfo: this.tzInfo,
      geo: this.birthGeo,
      placeName: extractText(this.placeName),
      gender: this.gender,
      roddenValue: this.roddenValue,
      eventType: this.eventType
    }
  }

  initDob(): void {
    if (!validDateTimeString(this.dob)) {
      const currTs = new Date().getTime() - 30 * 365.25 * 24 * 60 * 60 * 1000;
      const refDt = new Date(currTs).toISOString();
      const dtStr = refDt.split("T").shift();
      if (typeof dtStr === "string") {
        this.dobDate = dtStr;
      } 
    }
    fetchSubjectData().then(result => {
      if (result.valid) {
        this.eventTypes = result.eventTypes;
        this.roddenOptions = result.rodden.map((row:any) => {
          const name = [row.key, row.name.replace(/\ssomeone('s)?/, '').split('-').pop().trim()].join(': ');
          const key = row.value.toString();
          return {
            key, name
          }
        });
      }
    })
  }

  mapPlace(row: any = null): NameObjValue {
    let name = "";
    let value = {};
    if (row instanceof Object) {
      const { fullName, region, country  } = row;
      const parts = [fullName];
      const simpleRegion = sanitize(region);
      if (simpleRegion.includes(sanitize(fullName)) === false) {
        parts.push(region);
      }
      if (simpleRegion.includes(sanitize(country)) === false) {
        parts.push(country);
      }
      name = parts.join(', ');
      value = row;
    }
    return {
      name,
      value,
    }
  }

  matchPlacename(e: any = null): void {
    if (e instanceof Object && Object.keys(e).includes("query")) {
      const search = e.query.trim();
      if (!this.isFetching && notEmptyString(search)) {
        this.isFetching = true;
        fetchPlacenames(search).then((data) => {
          if (data.valid) {
            this.suggestedLocations = data.items.map(this.mapPlace);
            setTimeout(() => {
              this.isFetching = false;
            }, 50);
          }
        });
      }
      setTimeout(() => {
        this.isFetching = false;
      }, 1000);
    }
    setTimeout(() => {
      this.isFetching = false;
    }, 2000);
  }

  selectPlacename(e: any = null): void {
    if (e instanceof Object && Object.keys(e).includes('value')) {
      const item = e.value.value;
      let { lat, lng } = item;
      if (isNumeric(lat) && isNumeric(lng)) {
        lat = smartCastFloat(lat);
        lng = smartCastFloat(lng);
        this.birthGeo = new GeoLoc({lat, lng });
        this.tempLocMatch = [item.fullName, item.country].join(", ");
        const datePart = this.dobDate;
        getTzData(this.birthGeo, datePart).then(result => {
          if (result.valid) {
            const tzAbbr = notEmptyString(result.shortTz) && /^[A-Z]+$/i.test(result.shortTz)? result.shortTz : "";
            const parts = [["UTC", hourMinTz(result.tzOffset, true)].join(" ")];
            this.offsetHrs = smartCastFloat(result.tzOffset / (60 * 60)).toString();
            if (notEmptyString(tzAbbr)) {
              parts.unshift(tzAbbr);
            }
            this.tzInfo = parts.join(" / ");
          }
        });
      }
    }
  }

  async save(saveUser = true): Promise<void> {
    let puid = '';
    if (this.hasAccountEmail && saveUser) {
      const stored = fromLocal('publicuser', 52 * 7 * 24 * 60 * 60);
      const pUser = stored.expired? new User({
        identifier: this.identifier,
        nickName: this.nickName,
        gender: this.gender,
        dob: this.dob
      }) : new User(stored.data);
      puid = pUser.id;
      if (this.hasAccountEmail) {
        if (isAnonUser(pUser.identifier) || this.identifier !== pUser.identifier) {
          pUser.identifier = this.identifier;
        }
      }
      await savePublicUser(pUser).then(result => {
        if (result.valid && notEmptyString(result._id, 12)) {
          toLocal('publicuser', result);
          puid = result._id;
        }
      });
    }
    const payload = {
      subject: this.subject,
      identifier: this.identifier,
      geo: this.geo,
      context: this.context,
      puid
    }
    this.$emit('update', payload);
    toLocal(this.cacheKey, payload.subject);
  }

  loadRemote(): void {
    fetchPublicUser(this.identifier).then(result => {
      if (result.valid && result.miniCharts.length > 0) {
        const mc = result.miniCharts[0];
        if (mc instanceof Object) {
          this.emitter.emit('chart-loaded', mc);
          toLocal('publicuser', result);
          this.syncPerson(mc.p1, 'main');
          this.emitter.emit('sync-person', {
            data: mc.p2,
            context: 'partner'
          });
          if (result.miniCharts.length > 1) {
            this.emitter.emit('sync-remote-list', result.miniCharts.slice(1));
          }
        }
      }
    })
  }

  syncPerson(data: any = null, context = 'none'): void {
    if (data instanceof Object && context === this.context) {
      const { name, jd, gender, placeName, geo, tzOffset, roddenValue } = data;
      const bGeo = geo instanceof Object ? { ...geo } : null;
      let secsOffset = 0;
      if (typeof name === "string") {
        this.nickName = name;
      }
      if (typeof gender === "string") {
        this.gender = gender;
      }
      if (typeof placeName === "string") {
        this.placeName = placeName;
      }
      if (typeof tzOffset === "number") {
        this.offsetHrs = (tzOffset / 3600).toString();
        secsOffset = tzOffset;
      }
      if (typeof roddenValue === "number") {
        this.roddenValue = roddenValue.toString();
      } else if (typeof roddenValue === "string") {
        this.roddenValue;
      }
      if (typeof jd === "number") {
        const jdObj = julToDateParts(jd, secsOffset);
        this.dobDate = jdObj.ymdDate;
        this.dobTime = jdObj.hms;
      }
      if (bGeo instanceof Object) {
        this.birthGeo = new GeoLoc(bGeo);
      }
      this.save(false);
    }
  }

  resetForm(): void {
    if (this.context === "partner") {
      this.identifier = "";
    }
    this.nickName = "";
    this.gender = "";
    this.geo = new GeoLoc();
    this.placeName = "";
    this.eventType = "birth";
    this.roddenValue = '200';
    this.offsetHrs = '0';
    const year = new Date().getFullYear() - 30;
    this.dobDate = [year, "01", "01"].join("-");
    this.dobTime = "12:00";
    this.emitter.emit('set-next-pair-num', true);
  }

  checkLocation(): void {
    fetchGeoLocation((geo: any) => {
      if (geo instanceof Object) {
        const { lat, lng } = geo;
        if (isNumeric(lat) && isNumeric(lng)) {
          this.geo = new GeoLoc(geo);
          this.user.geo = this.geo;
          toLocal("current_geo", geo);
        }
      }
    })
  }

  handleEmailFocus(e: any): void {
    if (this.isAnon) {
      this.anonIdentifier = this.identifier.trim();
      this.identifier = '';
    }
  }

  handleEmailBlur(e: any): void {
    if (this.identifier.length < 3) {
      this.identifier = this.anonIdentifier;
    }
  }

  get isAnon(): boolean {
    return isAnonUser(this.identifier)
  }

  triggerUpdate(): void {
    const st2 = fromLocal('publicuser');
    const { data } = st2;
    const puid = data instanceof Object ? data._id : '';
    const payload = {
      subject: this.subject,
      identifier: this.identifier,
      geo: this.birthGeo,
      context: this.context,
      puid
    }
    this.$emit('update', payload);
    toLocal(this.cacheKey,this.subject);
  }

  @Watch('dobDate')
  changeDobDate(): void {
    setTimeout(() => {
      this.triggerUpdate();
    }, 125)
  }

  @Watch('offsetHrs')
  changeOffsetHrs(): void {
    setTimeout(() => {
      this.triggerUpdate();
    }, 125)
  }

   @Watch('birthGeo')
  changeBirthGeo(): void {
    setTimeout(() => {
      this.triggerUpdate();
    }, 250)
  }

  @Watch('dobTime')
  changeDobTime(): void {
      setTimeout(() => {
      this.triggerUpdate();
    }, 125)
  }

  @Watch('nickName')
  changeNickName(): void {
    this.triggerUpdate();
  }

  @Watch('gender')
  changeGender(): void {
     this.triggerUpdate();
  }

  @Watch('roddenValue')
  changeRoddenValue(): void {
     this.triggerUpdate();
  }

  @Watch('eventType')
  changeEventType(): void {
     this.triggerUpdate();
  }

}
