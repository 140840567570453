
import { degAsDms, roundToStep, shortenName, smartCastInt } from '@/api/converters';
/* import { filterKeyPairEitherDir } from '@/api/helpers'; */
import { AspectLineSet, ComparisonMode, GraphData, GraphLineDataSet, KeyName, LineConfig } from '@/api/interfaces';
import { julToDateParts } from '@/api/julian-date';
import { fromLocal, toLocal } from '@/api/localstore';
import { getLocalSettings, saveLocalSettings } from '@/api/methods';
import { LngPairChartData } from '@/api/models/lng-pair-chart-data';
import { allModeGrahaKeys, aspectRows, defaultLinePropSet, defaultLocalSettings, defaultOuterOrb, emptyLabels, grahaDict, grahaKeyIndex, lineCombos, matchAspectSymbol, matchGrahaName, orbBase } from '@/api/setings';
import { notEmptyString } from '@/api/validators';
import { capitalize } from '@vue/shared';
import { Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

export default class LineGraph extends Vue {
  @Prop({ default: () => new LngPairChartData() }) readonly chartData: LngPairChartData = new LngPairChartData();

  lineConfigs: LineConfig[] = [];

  /* grahaPairs: KeyName[] = [
    { key: "su_ve", name: "☉ / ♀"},
    { key: "ve_ve", name: "♀ / ♀"},
    { key: "ve_ma", name: "♀ / ♂"},
    { key: "ds_any", name: "Dsc. with any"}
  ]; */

  p1Keys = ["su", "ve", "ma"];

  p2Keys = ["su", "ve", "ma"];

  //selectedGrahaPair = "su_ve";

  graphData: GraphData = {
    labels: emptyLabels,
    datasets: [
      defaultLinePropSet
    ]
  }

  legendOn = true;

  graphEnabled = true;

  options = {}

  lines: AspectLineSet[] = [];

  yearSpan = [-15, 5];

  startYear = 1992;

  endYear = 2027;

  maxStart = -70;

  maxEnd = 30;

  step = 5;

  sliding = false;

  loaded = false;

  building = false;

  created(): void {
    this.buildOptions();
    this.initLineConfigs();
    this.emitter.on('update-analysis', (sKey: string) => {
      if (sKey) {
        this.sync();
      }
    });
    this.emitter.on('clear-user-data', (ok: boolean) => {
      if (ok) {
        this.sync();
      }
    });
    this.restoreKeys();  
  }

  restoreKeys(): void {
    const stored = fromLocal('grahakeys')
    if (stored.valid) {
      if (stored.data instanceof Object) {
        const {p1Keys, p2Keys} = stored.data;
        if (p1Keys instanceof Array && p2Keys instanceof Array) {
          this.p1Keys = p1Keys;
          this.p2Keys = p2Keys;
        }
      }
    }
  }

  storeKeys(): void {
    toLocal('grahakeys', {p1Keys: this.p1Keys, p2Keys: this.p2Keys});
  }
  
  mounted(): void {
    setTimeout(this.init, 375);
  }

  init(): void {
    this.sync();
  }

  buildOptions(): void {
    this.options = {
      plugins: {
        legend: {
          display: this.legendOn,
          position: 'bottom',
          labels: {
            boxWidth: 8,
            boxHeight: 10
          }
        },
        tooltip: {
          callbacks: {
            label: (row: any = null): string => {
              let str = '';
              if (row instanceof Object) {
                row.formattedValue = degAsDms(row.raw);
                str = [row.dataset.label, row.formattedValue].join(': ');
              }
              return str;
            },
            labelColor: (context: any) => {
              const {backgroundColor, borderColor} = context.dataset;
              return {
                  borderColor,
                  backgroundColor,
                  borderWidth: 0,
                  borderRadius: 0,
              };
            }
          }
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          min: 0,
          max: defaultOuterOrb
        }
      },
      aspectRatio: 2.16667
    }
  }

  initLineConfigs(): void {
    const { yearSpan, futureYears } = getLocalSettings();
    const end = roundToStep(futureYears, this.step);
    const start = roundToStep(yearSpan, this.step) - end;
    this.yearSpan = [0-start, end];
    this.syncYears();
    setTimeout(this.buildLineGraphData, 125);
  }

  syncYears(): void {
    this.startYear = this.currYear + this.yearSpanStart;
    this.endYear = this.currYear + this.yearSpanEnd;
  }

  syncYearSpan(): void {
    const span = this.endYear - this.startYear;
    if (this.startYear < this.minComparisonYear) {
      this.startYear = this.minComparisonYear;
      const maxSpan = span < 70 ? span : 70;
      this.endYear = this.startYear + maxSpan;
    }
    const start = this.startYear - this.currYear;
    const end = this.endYear - this.currYear;
    this.yearSpan = [start, end];
  }

  buildLineGraphData(): void {
    this.lineConfigs = [];
    aspectRows.forEach(row => {
      lineCombos.forEach(lc => {
        if (this.grahaPairIsSelected(lc)) {
          this.lineConfigs.push({...lc, color: row.cg, aspectKey: row.key});
        }
      })
    });
  }

  get minComparisonYear(): number {
    return this.chartData.minYear;
  }

  buildGrahaOpts(set = 1): KeyName[] {
    return allModeGrahaKeys.map((key, index) => {
      const itemKey = [key, index, set].join('-')
      const dictRow = grahaDict.find(row => row.key === key)
      const name = dictRow instanceof Object ? dictRow.name : key;
      const icon = ['icon', key].join('-');
      return {
        key,
        icon,
        name,
        itemKey
      }
    })
  }

  get grahOpts1(): KeyName[] {
    return this.buildGrahaOpts(1);
  }

  get grahOpts2(): KeyName[] {
    return this.buildGrahaOpts(2);
  }

  get symbols(): KeyName[] {
    const gsRows = grahaDict.map(row => {
      return {
        key: row.key,
        name: row.name,
        icon: row.short
      }
    })
    const asRows = aspectRows.map(row => {
      return {
        name: capitalize(row.key),
        key: row.key,
        icon: row.uc
      }
    })
    const extraRows: KeyName[] = [
      {
        key: "n",
        name: "Natal",
        icon: "ᴺ",
      },
      {
        key: "p",
        name: "Progression",
        icon: "ᴾ",
      }
    ];
    return [...gsRows, ...asRows, ...extraRows].map((row, ri) => {
      const itemKey = ['symbol', row.key, ri].join('-');
      const classNames = notEmptyString(row.icon) ? ["abbr", row.key] : ["icon", row.key];
      return  {...row, itemKey, classNames };
    })
  }

  /* grahaPairIsSelected(lc: LineConfig): boolean {
    const selPairs = [this.selectedGrahaPair.split("_")];
    return selPairs.some(([one, two]) => {
      if (two === "any") {
        return lc.p1Key === one || lc.p2Key === two;
      } else {
        return filterKeyPairEitherDir([one, two], lc)
      }
    });
  } */

  grahaPairIsSelected(lc: LineConfig): boolean {
    return this.p1Keys.includes(lc.p1Key) && this.p2Keys.includes(lc.p2Key);
  }

  get grahaGroupLabel(): string {
    return `Select graha pairs`;
  }

  get p1Name(): string {
    return shortenName(this.chartData.p1.name);
  }

  get p2Name(): string {
    return shortenName(this.chartData.p2.name);
  }

  toLineLabel(ln: LineConfig): string {
    const p1Prefix = ln.comparisonMode === ComparisonMode.BIRTH_TO_PROGRESS ? 'ᴺ' : 'ᴾ';
    const p2Prefix = ln.comparisonMode !== ComparisonMode.PROGRESS_TO_BIRTH ? 'ᴾ' : 'ᴺ';
    const p1Name = matchGrahaName(ln.p1Key);
    const p2Name = matchGrahaName(ln.p2Key);
    return [p1Prefix, p1Name, matchAspectSymbol(ln.aspectKey), p2Prefix, p2Name].join(" ")
  }

  sync(): void {
    if (!this.building) {
      this.building = true;
      this.buildLineGraphData();
      this.loaded = false;
      this.lines = this.chartData.calcAspectLines(this.lineConfigs);
      this.graphData.labels = this.chartData.p1.progressSets.map(ps => julToDateParts(ps.jd).monthYear);
      this.graphData.datasets = [];
      const ctrlKeys: string[] = [];
      for (const lineData of this.lines) {
        const { p1Key, p2Key, comparisonMode } = lineData.line;
        const isPP = lineData.line.comparisonMode === ComparisonMode.PROGRESS_TO_PROGRESS;
        const p1First = isPP ? grahaKeyIndex(p1Key) <=  grahaKeyIndex(p2Key) : true;
        const p1Ref = isPP ? p1First ? p1Key : p2Key : p1Key;
        const p2Ref = isPP ? p1First ? p2Key : p1Key : p2Key;
        const ctrlKey = [p1Ref,p2Ref, lineData.line.aspectKey, comparisonMode].join('-');
        if (ctrlKeys.includes(ctrlKey) === false) {
          ctrlKeys.push(ctrlKey);
          this.graphData.datasets.push({
            ...defaultLinePropSet,
            borderWidth: 2,
            borderColor: lineData.line.color,
            backgroundColor: lineData.line.color,
            label: this.toLineLabel(lineData.line),
            data: lineData.data.map(ln => ln.aspectDiff)
          });
        }
      }
      this.graphData.datasets.push({
        ...defaultLinePropSet,
        label: `Orb`,
        fill: true,
        borderColor: 'rgba(255,255,0,1)',
        backgroundColor: 'rgba(255,255,0,0.1875)',
        borderWidth: 1,
        pointRadius: 0,
        data: this.baseLine(),
        showLine: false
      })
      setTimeout(() => {
        this.loaded = true;
      }, 1000);

      setTimeout(() => {
        this.building = false;
      }, 125);
    }
  }

  baseLine(): number[] {
    const pLine = this.chartData.isValid? this.chartData.p1.progressSets : Array(81).fill(0);
    return pLine.map(_ => {
      return orbBase;
    })
  }

  toggleSecond(): void {
    this.toggleLine(3)
  }

  getLine(index = 0): GraphLineDataSet {
    return this.graphData.datasets.length > index ? this.graphData.datasets[index] : defaultLinePropSet;
  }

  getLabel(index = 0): string {
    return this.getLine(index).label;
  }

  toggleLine(index = 0): void {
    if (this.graphData.datasets.length > index) {
      const hidden = this.graphData.datasets[index].hidden === true;
      this.graphData.datasets[index].hidden = !hidden;
    }
  }

  get secondItem(): string {
    return this.getLabel(3);
  }

  get yearSpanStart(): number {
    return this.yearSpan.length > 0 ? this.yearSpan[0] : (0 - defaultLocalSettings.yearSpan);
  }

  get yearSpanEnd(): number {
    return this.yearSpan.length > 1 ? this.yearSpan[1] : defaultLocalSettings.futureYears;
  }

  get currYear(): number {
    return new Date().getFullYear();
  }

  get maxYear(): number {
    return this.currYear + 100;
  }

  get maxStartYear(): number {
    return this.currYear + 5;
  }

  getYearRangeLabel(yearDiff = 0): string {
    const future = yearDiff > 0;
    
    const absVal = Math.abs(yearDiff);
    const plural = absVal === 1 ? '' : 's';
    const yearLbl = ['year', plural].join('');
    const suffix = future ? 'from now' : 'ago';
    return `${absVal} ${yearLbl} ${suffix}`
  }

  get startYearLabel(): string {
    return `Start year (${this.yearStartLabel})`;
  }

  get endYearLabel(): string {
    return `End year (${this.yearEndLabel})`;
  }

  get endRangeTip(): string {
    return `Update year range: ${this.yearStartLabel} to ${this.yearEndLabel}`
  }

  get yearStartLabel(): string {
    return this.getYearRangeLabel(this.yearSpanStart)
  }

  get yearEndLabel(): string {
    return this.getYearRangeLabel(this.yearSpanEnd);
  }

  get yearStartYearVal(): number {
    return this.currYear + this.yearSpanStart;
  }

  get yearEndYearVal(): number {
    return this.currYear + this.yearSpanEnd;
  }

/*   showLegend(): void {
    this.legendOn = true;
  }

  hideLegend(): void {
    this.legendOn = false;
  } */

  get legendTip(): string {
    return this.legendOn? 'Hide graph legend' : 'Show graph legend';
  }

  toggleLegend(): void {
    this.legendOn = !this.legendOn;
    this.buildOptions();
  }

  wrapperClasses(): string[] {
    const cls: string[] = [];
    if (this.legendOn) {
      cls.push('show-legend');
    }
    return cls;
  }

 /*  get startMinusShow(): boolean {
    return this.yearSpanStart > this.maxStart;
  }

  get startPlusShow(): boolean {
    return this.yearSpanStart < (0 - this.step);
  }

  get endMinusShow(): boolean {
    return this.yearSpanStart > this.step;
  }

  get endPlusShow(): boolean {
    return this.yearSpanEnd < this.maxEnd;
  }

  get startMinusClasses(): string[] {
    return this.startMinusShow ? [] : ['disabled'];
  }

  get startPlusClasses(): string[] {
    return this.startPlusShow ? [] : ['disabled'];
  }

  get endMinusClasses(): string[] {
    return this.endMinusShow ? [] : ['disabled'];
  }

  get endPlusClasses(): string[] {
    return this.endPlusShow ? [] : ['disabled'];
  } */

  /* minusStartStep(): void {
    this.moveStep(-1, false);
  }

  plusStartStep(): void {
    this.moveStep(-1, true);
  }

  minusEndStep(): void {
    this.moveStep(1, false);
  }

  plusEndStep(): void {
    this.moveStep(1, true);
  } */

  /* moveStep(direction = 1, plus = true): void {
    const val = direction < 0 ? this.yearSpanStart : this.yearSpanEnd;
    const index = direction < 0 ? 0 : 1;
    const stepVal = plus ? 5 : -5;
    if (val >= this.maxStart && val <= this.maxEnd) {
      this.yearSpan[index] = val + stepVal;
      this.updateYearSpan(this.yearSpan);
    }
  } */

  moveTooltip(direction = 1, plus = true): string {
    const verb = direction < 1 ? "Start" : "End";
    const adverb = plus ? "later" : "earlier";
    const object = [this.step, 'years'].join(' ');
    return [verb, object, adverb].join(" ");
  }
  
  @Watch('chartData')
  changeChartData(newVal: any = null): void {
    if (newVal instanceof LngPairChartData) {
      setTimeout(this.init, 375);
    }
  }

  /* @Watch('selectedGrahaPair')
  changeSelectedGrahaPair(newVal = ""): void {
    if (notEmptyString(newVal)) {
      setTimeout(() => {
        this.buildLineGraphData();
        this.sync();
      }, 250);
    }
  } */
  @Watch('p1Keys')
  changeP1Keys(newVal = []): void {
    if (newVal instanceof Array) {
      this.storeKeys();
      setTimeout(() => {
        this.sync();
      }, 250);
    }
  }

  @Watch('p2Keys')
  changeP2Keys(newVal = []): void {
    if (newVal instanceof Array) {
      this.storeKeys();
      setTimeout(() => {
        this.sync();
      }, 250);
    }
  }

  saveSettings(newVal: number[] = []): void {
    const [start, future] = newVal;
      /* const startInt = roundToStep(start, this.step);
      const futureYears = roundToStep(future, this.step); */
      const startInt = smartCastInt(start);
      const futureYears = smartCastInt(future);
      const yearSpan = futureYears - startInt;
      const settings = {
        yearSpan,
        futureYears
      }
      this.sliding = true;
      saveLocalSettings(settings);
  }

  updateYearSpan(newVal: number[] = []): void {
    if (!this.sliding ) {
      this.saveSettings(newVal);
      setTimeout(()=> {
        this.emitter.emit('update-chart', true);
      }, 25);
      setTimeout(()=> {
        this.sliding = false;
      }, 125);
    }
  }

  updateYearRange(): void {
    setTimeout(()=> {
      this.updateYearSpan(this.yearSpan)
    }, 125);
  }

  @Watch('startYear')
  changeStartYear(newVal: number, oldVal: number): void {
    if (newVal !== oldVal && newVal < this.endYear) {
      this.syncYearSpan();
    }
  }

  @Watch('endYear')
  changeEndYear(newVal: number, oldVal: number): void {
    if (newVal !== oldVal && newVal > this.startYear) {
      this.syncYearSpan();
    }
  }

  /* @Watch('yearSpan')
  changeYearSpan(newVal: number[] = [], oldValue = []): void {
    if (this.loaded && newVal instanceof Array && newVal.length > 1) {
      if (newVal[0] != oldValue[0] || newVal[1] !== oldValue[1]) {
        this.updateYearSpan(newVal)
      }
    }
  } */

}
