export interface KeyName {
  key: string;
  name: string;
  icon?: string;
  itemKey?: string;
  classNames?: string[];
}

export interface KeyValue {
  key: string;
  value: number;
  type?: string;
}

export interface NameObjValue {
  name: string;
  value: any;
}

export interface AspectRow {
  key: string;
  angles: number[];
  cg: string;
}

export interface AspectResult {
  lng1: number;
  lng2: number;
  aspectDiff: number;
  diff: number;
}

export enum ComparisonMode {
  BIRTH_TO_PROGRESS,
  PROGRESS_TO_BIRTH,
  PROGRESS_TO_PROGRESS,
}

export interface LineConfig {
  p1Key: string;
  p2Key: string;
  comparisonMode: ComparisonMode;
  aspectKey: string;
  color?: string;
  backgroundColor?: string;
}

export interface AspectLineSet {
  line: LineConfig;
  data: AspectResult[];
}

export interface GraphLineDataSet {
  data: number[];
  label: string;
  fill: boolean;
  borderWidth: number;
  pointRadius?: number;
  pointBackgroundColor?: string;
  pointBorderColor?: string;
  borderColor?: string;
  backgroundColor?: string;
  tension?: number;
  hidden?: boolean;
  showLine?: boolean;
}

export interface GraphLabel {
  text: string;
  display: boolean;
}

export interface GraphData {
  labels: string[];
  datasets: GraphLineDataSet[];
}

export interface LocalSettings {
  yearSpan: number;
  futureYears: number;
}

export interface LatLngDisplay {
  lat: string;
  lng: string;
}
