import { ComparisonMode, GraphLineDataSet, LineConfig } from "./interfaces";

export const orbBase = 2.017;

export const defaultOuterOrb = 10;

export const aspectRows = [
  { key: "conjunction", angles: [0], cg: "rgba(204,0,51,0.5)", uc: "☌" },
  { key: "opposition", angles: [180], cg: "rgba(136,0,136,0.5)", uc: "☍" },
  { key: "trine", angles: [120, 240], cg: "rgba(51,0,204,0.5)", uc: "△" },
  { key: "square", angles: [90, 270], cg: "rgba(255,0,0,0.5)", uc: "☐" },
  { key: "quincunx", angles: [150], cg: "rgba(0,0,0,0.5)", uc: "⌅" },
];

export const matchAspectSymbol = (key = ""): string => {
  const row = aspectRows.find((r) => r.key === key);
  return row instanceof Object ? row.uc : "";
};

export const matchAspectName = (key: string): string => {
  switch (key) {
    case "conjunction":
      return "conjunct";
    case "opposition":
      return "opposite";
    default:
      return key;
  }
};

export const grahaDict = [
  {
    key: "su",
    short: "☉",
    name: "Sun",
  },
  {
    key: "mo",
    short: "☽",
    name: "Moon",
  },
  {
    key: "me",
    short: "☿",
    name: "Mercury",
  },
  {
    key: "ve",
    short: "♀",
    name: "Venus",
  },
  {
    key: "ma",
    short: "♂",
    name: "Mars",
  },
  {
    key: "ju",
    short: "♃",
    name: "Jupiter",
  },
  {
    key: "sa",
    short: "♄",
    name: "Saturn",
  },
  {
    key: "ur",
    short: "♅",
    name: "Uranus",
  },
  {
    key: "ne",
    short: "♆",
    name: "Neptune",
  },
  {
    key: "pl",
    short: "♇",
    name: "Pluto",
  },
  {
    key: "ra",
    short: "☊",
    name: "Rahu",
  },
  {
    key: "ke",
    short: "☋",
    name: "Ketu",
  },
  {
    key: "ds",
    short: "Dsc.",
    name: "Descendant",
  },
];

export const matchGrahaName = (key: string, long = false): string => {
  const row = grahaDict.find((r) => r.key === key);
  return row instanceof Object ? (long ? row.name : row.short) : key;
};

export const innerGrahaKeys = ["as", "su", "mo", "me", "ve", "ma", "ju", "sa"];

export const outGrahaKeys = ["ur", "ne", "pl", "ra", "ke"];

export const allModeGrahaKeys = [...innerGrahaKeys, ...outGrahaKeys];

export const grahaKeyIndex = (key = ""): number =>
  allModeGrahaKeys.indexOf(key);

const allModes = [
  ComparisonMode.BIRTH_TO_PROGRESS,
  ComparisonMode.PROGRESS_TO_BIRTH,
  ComparisonMode.PROGRESS_TO_PROGRESS,
];

export const lineCombos: LineConfig[] = allModeGrahaKeys
  .map((p1Key) => {
    return allModeGrahaKeys
      .filter((p2Key) => {
        return innerGrahaKeys.includes(p1Key) || innerGrahaKeys.includes(p2Key);
      })
      .map((p2Key) => {
        const modes =
          p1Key !== "as" && p2Key !== "as"
            ? allModes
            : p1Key === "as" && p2Key === "as"
            ? []
            : p1Key === "as"
            ? [0]
            : [1];
        return modes.map((comparisonMode) => {
          return {
            p1Key,
            p2Key,
            comparisonMode,
          };
        });
      })
      .reduce((a, b) => a.concat(b));
  })
  .reduce((a, b) => a.concat(b))
  .map((row) => {
    return {
      ...row,
      color: "",
      aspectKey: "",
    };
  });

const emptyDataLine: number[] = [0];

export const defaultLinePropSet: GraphLineDataSet = {
  label: "",
  data: emptyDataLine,
  fill: false,
  borderWidth: 1,
  pointRadius: 4,
  pointBackgroundColor: "transparent",
  pointBorderColor: "transparent",
  borderColor: "rgb(204, 0, 0)",
  tension: 0.25,
  hidden: false,
};

export const defaultGenderOptions = [
  {
    key: "f",
    title: "Female",
  },
  {
    key: "m",
    title: "Male",
  },
  {
    key: "o",
    title: "Other",
  },
];

export const graphColours = [
  "rgba(204,0,0, 0.5)",
  "rgba(0,204,0, 0.5)",
  "rgba(0,0,204, 0.5)",
  "rgba(153,153,0, 0.5)",
  "rgba(153,0,153, 0.5)",
  "rgba(0,153,153, 0.5)",
];

export const matchGraphColour = (index = 0): string => {
  const targetIndex = index >= 0 ? index % graphColours.length : 0;
  return graphColours[targetIndex];
};

export const defaultInstructions = `
  <h2>Progression Chart</h2>
  <h3>Instructions</h3>
  <ul>
    <li>Enter your name</li>
    <li>Set your date and time of birth</li>
    <li>Enter the nearest city or town to your place of birth and choose the best match from the suggestions to assign your birth coordinates and time zone.</li>
    <li>Do the same for your partner.</li>
    <li>Set the correct gender, event type and Rodden scale options</li>
    <li>Press save to reveal the progression chart.</li>
  </ul>
`;

export const emptyLabels: string[] = [];

export const defaultLocalSettings = {
  yearSpan: 20,
  futureYears: 5,
};
