import { degAsDms } from "../converters";
import { calcAspect } from "../helpers";
import {
  AspectLineSet,
  AspectResult,
  ComparisonMode,
  LineConfig,
} from "../interfaces";
import { JulDate, julToDateParts } from "../julian-date";
import { defaultOuterOrb } from "../setings";
import { notEmptyString } from "../validators";
import { GeoLoc } from "./geo";
import { GrahaSet } from "./graha-set";

export class ProgressSet {
  jd = 0;
  pd = 0;
  bodies: GrahaSet = new GrahaSet();
  ayanamsha = 0;

  constructor(inData: any = null) {
    if (inData instanceof Object) {
      const { jd, pd, bodies, ayanamsha } = inData;
      if (bodies instanceof Object) {
        this.bodies = new GrahaSet(bodies);
      }
      if (typeof jd === "number") {
        this.jd = jd;
      }
      if (typeof pd === "number") {
        this.pd = pd;
      }
      if (typeof ayanamsha === "number") {
        this.ayanamsha = ayanamsha;
      }
    }
  }
}

export interface CoreData {
  jd: number;
  name: string;
  geo: GeoLoc;
  ayanamsha: number;
  birth: GrahaSet;
}

export class LngChartData {
  jd = 0;
  name = "";
  gender = "";
  birth: GrahaSet = new GrahaSet();
  ayanamsha = 0;
  geo: GeoLoc = new GeoLoc();
  tzOffset = 0;
  placeName = "";
  progressSets: ProgressSet[] = [];
  roddenValue = 0;
  eventType = "birth";

  constructor(inData: any = null) {
    if (inData instanceof Object) {
      const {
        jd,
        geo,
        placeName,
        name,
        gender,
        birth,
        progressSets,
        ayanamsha,
        roddenValue,
        eventType,
        tzOffset,
      } = inData;
      if (birth instanceof Object) {
        this.birth = new GrahaSet(birth);
      }
      if (typeof jd === "number") {
        this.jd = jd;
      }
      if (geo instanceof Object) {
        this.geo = new GeoLoc(geo);
      }
      if (progressSets instanceof Array) {
        this.progressSets = progressSets.map((ps) => new ProgressSet(ps));
      }
      if (typeof ayanamsha === "number") {
        this.ayanamsha = ayanamsha;
      }
      if (typeof roddenValue === "number") {
        this.roddenValue = roddenValue;
      }
      if (typeof tzOffset === "number") {
        this.tzOffset = tzOffset;
      }
      if (typeof name === "string") {
        this.name = name;
      }
      if (typeof gender === "string") {
        this.gender = gender;
      }
      if (typeof eventType === "string") {
        this.eventType = eventType;
      }
      if (typeof placeName === "string") {
        this.placeName = placeName;
      }
    }
  }

  birthLng(key = "su"): number {
    return this.birth.lng(key);
  }

  extractCore(name = ""): CoreData {
    return {
      jd: this.jd,
      name,
      geo: this.geo,
      ayanamsha: this.ayanamsha,
      birth: this.birth,
    };
  }

  get jDate(): JulDate {
    return julToDateParts(this.jd, this.tzOffset);
  }

  get info(): string {
    return `${this.name} (${this.jDate.monthYear})`;
  }

  get uniqueId(): string {
    return [this.jd, this.geo.lat, this.geo.lng].join("-");
  }

  progressLine(key = "su"): number[] {
    switch (key) {
      case "su":
        return this.suLine;
      case "ve":
        return this.veLine;
      case "ma":
        return this.maLine;
      default:
        return this.grahaLine(key);
    }
  }

  get suLine(): number[] {
    return this.progressSets.map((row) => row.bodies.su);
  }

  get veLine(): number[] {
    return this.progressSets.map((row) => row.bodies.ve);
  }

  get maLine(): number[] {
    return this.progressSets.map((row) => row.bodies.ma);
  }

  get locString(): string {
    return [
      degAsDms(this.geo.lat, "lat", 0),
      degAsDms(this.geo.lng, "lng", 0),
    ].join(", ");
  }

  grahaLine(gk = ""): number[] {
    return this.progressSets.map((row) => {
      return row.bodies.lng(gk);
    });
  }
}

export class Relationship {
  type = "marriage";

  startYear?: number;
  endYear?: number;
  span?: number;
}

export class LngPairChartData {
  p1: LngChartData = new LngChartData();

  p2: LngChartData = new LngChartData();

  relationship = new Relationship();

  key = "";

  constructor(p1: any = null, p2: any = null, key = "") {
    if (p1 instanceof Object) {
      this.p1 = new LngChartData(p1);
    }
    if (p2 instanceof Object) {
      this.p2 = new LngChartData(p2);
    }
    if (notEmptyString(key, 4)) {
      this.key = key;
    }
  }

  get names(): string {
    return [this.p1.name, this.p2.name].join(" ⇔ ");
  }

  get num(): number {
    let num = 0;
    if (notEmptyString(this.key) && /_\d+/.test(this.key)) {
      const parts = this.key.split("_");
      if (parts.length > 1) {
        num = parseInt(parts[parts.length - 1], 10);
      }
    }
    return num;
  }

  get info(): string {
    return [this.p1.info, this.p2.info].join(" ⇔ ");
  }

  get isValid(): boolean {
    return (
      this.p1.progressSets.length > 3 &&
      this.p1.progressSets.length === this.p2.progressSets.length
    );
  }

  get uniqueId(): string {
    return [this.p1.uniqueId, this.p2.uniqueId, this.num].join("--");
  }

  get minYear(): number {
    const years = [
      julToDateParts(this.p1.jd).year,
      julToDateParts(this.p2.jd).year,
    ];
    return Math.max(...years);
  }

  get locString(): string {
    return [this.p1.locString, this.p2.locString].join(" / ");
  }

  extractCore(name1 = "", name2 = ""): any {
    const p1 = this.p1.extractCore(name1);
    const p2 = this.p2.extractCore(name2);
    return {
      p1,
      p2,
      relationship: { ...this.relationship },
    };
  }

  calcAspectLine(
    p1Key = "su",
    p2Key = "ma",
    compMode: ComparisonMode,
    aspectKey = "conjunction"
  ): AspectResult[] {
    const p1Natal = compMode === ComparisonMode.BIRTH_TO_PROGRESS;
    const pToP = compMode === ComparisonMode.PROGRESS_TO_PROGRESS;
    const pLine = p1Natal
      ? this.p2.progressLine(p2Key)
      : this.p1.progressLine(p1Key);
    const nLng = pToP
      ? 0
      : p1Natal
      ? this.p1.birthLng(p1Key)
      : this.p2.birthLng(p2Key);
    const pLine2 = pToP ? this.p2.progressLine(p2Key) : [];
    const pLine2Length = pLine2.length;
    return pLine
      .filter((lng) => lng >= 0)
      .map((pLng: number, index) => {
        const otherLng = pToP && index < pLine2Length ? pLine2[index] : nLng;
        const lng1 = p1Natal ? otherLng : pLng;
        const lng2 = p1Natal ? pLng : otherLng;
        return calcAspect(lng1, lng2, aspectKey);
      })
      .filter((asp) => asp.lng1 >= 0 && asp.lng2 >= 0);
  }

  calcAspectLines(
    lines: LineConfig[] = [],
    maxDiff = defaultOuterOrb
  ): AspectLineSet[] {
    return lines
      .map((line) => {
        const data = this.calcAspectLine(
          line.p1Key,
          line.p2Key,
          line.comparisonMode,
          line.aspectKey
        );
        return {
          line,
          data,
        };
      })
      .filter((row) =>
        row.data.some((asr) => Math.abs(asr.aspectDiff) <= maxDiff)
      );
  }
}
