
import { fetchP2, fetchPublicUser, getLocalSettings } from '@/api/methods';
import { LngPairChartData } from '@/api/models/lng-pair-chart-data';
import { Subject } from '@/api/models/subject';
import { Options, Vue } from 'vue-class-component';
import PersonForm from "./PersonForm.vue"
import ChartList from "./ChartList.vue"
import LineGraph from "./LineGraph.vue"
import { fromLocal, toLocal } from '@/api/localstore';
import { defaultInstructions } from '@/api/setings';
import { notEmptyString, validEmail } from '@/api/validators';

@Options({
  components: {
    PersonForm,
    LineGraph,
    ChartList
  }
})
export default class Progression extends Vue {


  main = new Subject();
  partner = new Subject();
  chartData = new LngPairChartData();
  pairs: LngPairChartData[] = [];
  changing = false;
  puid = '';
  showChartList = false;
  newMode = false;
  currentRefNum = 1;
  showOverlay = true;
  listUpdating = false;

  created(): void {
    setTimeout(this.init, 250)
    this.emitter.on('update-chart', () => {
      this.save();
    });
    this.emitter.on('chart-loaded', (result: any) => {
      this.assignChartResult(result, true);
    })
    this.emitter.on('set-next-pair-num', () => {
      const nums = this.pairs.map(p => p.num);
      let nextNum = 1;
      if (nums.length > 0) {
        const maxNum = Math.max(...nums);
        nextNum = maxNum + 1;
      }
      this.currentRefNum = nextNum;
      this.newMode = true; 
    })
    this.emitter.on('load-pair', (key: string) => {
      const index = this.pairs.findIndex(p => p.key === key);
      if (index >= 0 && index < this.pairs.length) {
        const pairedItem = this.pairs[index];
        this.assignChartResult(pairedItem, false);
        this.emitter.emit('pair-loaded', pairedItem);
        this.currentRefNum = pairedItem.num;
        this.newMode = false;
        this.showChartList = false;
        setTimeout(() => {
          this.save();
        }, 500);
      }
    });
    this.emitter.on('escape', (ok: boolean) => {
      if (ok) {
        this.closeOverlay();
      }
    });
    this.emitter.on('sync-remote-list', (miniCharts: any[] = []) => {
      if (miniCharts.length > 0) {
        this.syncPairList(miniCharts)
      }
    });
    this.emitter.on('delete-pair', (index: number) => {
      if (index >= 0 && index < this.pairs.length) {
        const refPair = this.pairs[index];
        if (refPair instanceof LngPairChartData) {
          if (this.currentRefNum === refPair.num) {
            this.resetForm();
            this.showChartList = true;
          }
          this.pairs.splice(index, 1);
        }
        
      }
    })
    if (notEmptyString(window.location.hash, 5)) {
      const email = window.location.hash.split('#').pop();
      if (typeof email === "string" && validEmail(email)) {
        setTimeout(() => {
          this.emitter.emit('load-from-email', email);
        }, 500);
      }
    }
    this.emitter.on('clear-user-data', (ok: boolean) => {
      if (ok) {
        this.clearUserData();
      }
    });
  }

  init(): void {
    const stored = fromLocal('chart-data', 7 * 24 * 60 * 60);
    if (!stored.expired) {
      this.assignChartResult(stored.data, false);
    }
    this.syncCharts();
  }

  get showInstructions(): boolean {
    return this.showOverlay;
  }

  closeOverlay(): void {
    this.showOverlay = false;
    this.showChartList = false;
  }

  get wrapperClasses(): string[] {
    const activeClass = this.showInstructions? 'no-data' : 'has-data';
    const cls = [activeClass]
    if (this.showChartList) {
      cls.push('show-chart-list');
    }
    return cls;
  }

  get instructions(): string {
    return defaultInstructions.trim();
  }

  get total(): number {
    return this.pairs.length;
  }

  get hasPairs(): boolean {
    return this.total > 0;
  }
  
  get refNumTooltip(): string {
    return `Pair ${this.currentRefNum} of ${this.total}`;
  }

  get resetTooltip(): string {
    return `Reset both forms to enter a new pair`;
  }

  resetForm(): void {
    this.emitter.emit('reset-form', 'main');
    this.emitter.emit('reset-form', 'partner');
  }

  updatePerson(data: any = null): void {
    if (!this.changing && data instanceof Object) {
      const { context, subject, puid } = data;
      if (subject instanceof Object) {
        if (context === 'partner') {
          this.partner = new Subject(subject);
        } else {
          this.main = new Subject(subject);
        }
      }
      if (notEmptyString(puid)) {
        this.puid = puid;
      }
    }
  }

  clearUserData(): void {
    this.main = new Subject();
    this.partner = new Subject();
    this.chartData = new LngPairChartData();
    this.pairs = [];
  }

  syncCharts(): void {
    const stored = fromLocal('publicuser', 7 * 24 * 60 * 60);
    if (!stored.expired) {
      fetchPublicUser(stored.data.identifier).then(result => {
        if (result.valid) {
          if (notEmptyString(result._id)) {
            this.puid = result._id;
          }
          if (result.miniCharts instanceof Array) {
            this.syncPairList(result.miniCharts);
          }
        }
      })
    }
  }

  syncPairList(miniCharts: any[] = []): void {
    this.listUpdating = true;
    this.pairs = miniCharts.filter(p => p instanceof Object && Object.keys(p).includes('key')).map((pair: any) => new LngPairChartData(pair.p1, pair.p2, pair.key));
    
    const matchedIndex = this.pairs.findIndex(pair => this.chartData.p1.jd === pair.p1.jd && this.chartData.p2.jd === pair.p2.jd && this.chartData.p1.geo.lat === pair.p1.geo.lat && this.chartData.p2.geo.lat === pair.p2.geo.lat);
    if (matchedIndex >= 0) {
      this.currentRefNum = this.pairs[matchedIndex].num;
    }
    if (this.chartData.isValid) {
      this.showOverlay = false;
    }
    setTimeout(()=> {
      this.listUpdating = false;
    }, 250);
  }

  assignChartResult(result: any = null, store = false): void {
    if (result instanceof Object) {
      const { p1, p2, key } = result;
      if (p1 instanceof Object && p2 instanceof Object && notEmptyString(key)) {
        this.chartData = new LngPairChartData(p1, p2, key);
        const pIndex = this.pairs.findIndex(p => p.key === key);
        this.listUpdating = true;
        if (pIndex < 0) {
          this.pairs.push(this.chartData);
        } else {
          this.pairs[pIndex] = this.chartData;
        }
        this.showOverlay = false;
        this.showChartList = false;
        if (store) {
          toLocal('chart-data', result);
        }
        setTimeout(()=> {
          this.listUpdating = false;
        }, 250);
      }
    }
  }

  get hasData(): boolean {
    return this.chartData.isValid && this.chartData.p1.progressSets.length > 2;
  }

  get saveLabel(): string {
    return this.newMode ? "Save new chart" : "Save details";
  }

  addNew(): void {
    this.emitter.emit('add-new-chart', true);
  }

  save(): void {
    this.emitter.emit('save-person', 'both');
    const {yearSpan, futureYears } = getLocalSettings();
    setTimeout(() => { 
      if (this.main.isValid && this.partner.isValid) {
        if (!this.changing) {
          this.changing = true;
          fetchP2({
            dt1: this.main.utcDateStr,
            dt2: this.partner.utcDateStr,
            geo1: this.main.geo,
            geo2: this.partner.geo,
            n1: this.main.name,
            n2: this.partner.name,
            g1: this.main.gender,
            g2: this.partner.gender,
            r1: this.main.roddenValue,
            r2: this.partner.roddenValue,
            to1: this.main.tzOffset,
            to2: this.partner.tzOffset,
            pl1: this.main.placeName,
            pl2: this.partner.placeName,
            years: yearSpan,
            future: futureYears,
            puid: this.puid,
            pn: this.currentRefNum
          }).then((result: any) => {
            this.assignChartResult(result, true);
            setTimeout(() => {
              this.changing = false;
            }, 125);
          });
        }
      }/* 
      else {
        this.emitter.emit('load-remote', true);
      } */
    }, 125);
    setTimeout(() => {
      this.changing = false;
    }, 2000);
  }

  toggleChartList(): void {
    this.showChartList = !this.showChartList;
  }

}
