import { AspectResult, AspectRow, LineConfig } from "./interfaces";
import { aspectRows } from "./setings";
import { notEmptyString } from "./validators";

export const randomInt = (min = 1, max = 5): number => {
  const absMax = max - min + 1;
  return Math.floor(Math.random() * absMax * 0.999999) + min;
};

export const calcDist360 = (lng1: number, lng2: number): number => {
  const lngs = [lng1, lng2];
  lngs.sort((a, b) => (a < b ? -1 : 1));
  const [low, high] = lngs;
  const results = [high - low, low + 360 - high];
  const minDiff = Math.min(...results);
  return minDiff;
};

export const calcAspect = (
  lng1: number,
  lng2: number,
  aspectKey = "conjunction"
): AspectResult => {
  const aspectRow = aspectRows.find((row: AspectRow) => row.key === aspectKey);
  let aspectDiff = 360;
  const diff = calcDist360(lng1, lng2);
  if (aspectRow instanceof Object) {
    const aspectDiffs = aspectRow.angles.map((angle) =>
      calcDist360(diff, angle)
    );
    aspectDiff = Math.min(...aspectDiffs);
  }
  return { lng1, lng2, diff, aspectDiff };
};

export const filterKeyPairEitherDir = (
  pair: string[],
  lc: LineConfig
): boolean => {
  const one = pair.length > 0 ? pair[0] : "";
  const two = pair.length > 1 ? pair[1] : "";
  return (
    (lc.p1Key === one && lc.p2Key === two) ||
    (lc.p1Key === two && lc.p2Key === one)
  );
};

export const extractText = (input: any): string => {
  if (input instanceof Object) {
    const { name } = input;
    return typeof name === "string" ? name : "";
  } else if (typeof input === "string") {
    return input;
  } else {
    return "";
  }
};

export const generateAnonEmail = (): string => {
  const num = Math.floor(Math.random() * Math.pow(16, 12));
  return [num.toString(16), "anonymous.account"].join("@");
};

export const isAnonUser = (email: string): boolean => {
  if (notEmptyString(email)) {
    return email.endsWith("@anonymous.account");
  } else {
    return false;
  }
};
