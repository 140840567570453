import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import AutoComplete from "primevue/autocomplete";
/* import Textarea from "primevue/textarea"; */
import RadioButton from "primevue/radiobutton";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
/* import Slider from "primevue/slider"; */
import Tooltip from "primevue/tooltip";
import Chart from "primevue/chart";
import ConfirmDialog from "primevue/confirmdialog";
import mitt from "mitt";

import "primeicons/primeicons.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.min.css";
import "primevue/resources/themes/tailwind-light/theme.css";

const emitter = mitt();
const app = createApp(App);
app.config.globalProperties.emitter = emitter;

app.use(PrimeVue);
app.use(ConfirmationService);
app.component("Button", Button);
app.component("InputText", InputText);
app.component("InputNumber", InputNumber);
/* app.component("Textarea", Textarea); */
app.component("AutoComplete", AutoComplete);
app.component("RadioButton", RadioButton);
app.component("SelectButton", SelectButton);
app.component("Dropdown", Dropdown);
/* app.component("Slider", Slider); */
app.directive("tooltip", Tooltip);
app.component("Chart", Chart);
app.component("ConfirmDialog", ConfirmDialog);

app.mount("#findingyou-p2-widget");
