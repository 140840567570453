
import { Options, Vue } from 'vue-class-component';
import Progression from './components/Progression.vue';

@Options({
  components: {
    Progression,
  },
})
export default class App extends Vue {
  created(): void {
    window.addEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(e: any = null): void {
    
    if (e instanceof KeyboardEvent) {
      const key = e.code.toLowerCase();
      switch (key) {
       case "escape":
          this.emitter.emit('escape', true);
          break;
      }
    }
    
  }
}
