import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pi check-circle large"
}
const _hoisted_2 = { class: "row double name-row" }
const _hoisted_3 = { class: "row double" }
const _hoisted_4 = { class: "row location-row" }
const _hoisted_5 = {
  key: 0,
  class: "pi pi-check ok-icon"
}
const _hoisted_6 = { class: "lat" }
const _hoisted_7 = { class: "lat" }
const _hoisted_8 = { class: "tz-info" }
const _hoisted_9 = { class: "row double" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showEmail)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "column long email-row",
          onMouseenter: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleEmailFocus && _ctx.handleEmailFocus(...args))),
          onMouseleave: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleEmailBlur && _ctx.handleEmailBlur(...args)))
        }, [
          _createElementVNode("label", {
            for: "email-field",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadRemote && _ctx.loadRemote(...args)))
          }, _toDisplayString(_ctx.emailLabel), 1),
          _withDirectives(_createVNode(_component_InputText, {
            id: "email-field",
            type: "email",
            modelValue: _ctx.identifier,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.identifier) = $event)),
            placeholder: "Your email",
            class: _normalizeClass(["email-field", _ctx.emailClasses])
          }, null, 8, ["modelValue", "class"]), [
            [
              _directive_tooltip,
              _ctx.accountTip,
              void 0,
              { right: true }
            ]
          ]),
          (_ctx.showLoadRemote)
            ? _withDirectives((_openBlock(), _createElementBlock("i", {
                key: 0,
                class: "pi pi-cloud-download cloud-sync-trigger account-control",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.loadRemote && _ctx.loadRemote(...args)))
              }, null, 512)), [
                [
                  _directive_tooltip,
                  'Load remote data saved with this email',
                  void 0,
                  { left: true }
                ]
              ])
            : _createCommentVNode("", true)
        ], 32))
      : _createCommentVNode("", true),
    (_ctx.accountInfoEnabled)
      ? _withDirectives((_openBlock(), _createElementBlock("i", {
          key: 1,
          class: "pi pi-user account-identifier-trigger account-control",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.toggleEmail && _ctx.toggleEmail(...args)))
        }, null, 512)), [
          [
            _directive_tooltip,
            'Hide / show email account field',
            void 0,
            { right: true }
          ]
        ])
      : _createCommentVNode("", true),
    _createElementVNode("fieldset", {
      class: _normalizeClass(["details person-form", _ctx.wrapperClasses])
    }, [
      _createElementVNode("legend", null, _toDisplayString(_ctx.legend), 1),
      (_ctx.isValid)
        ? (_openBlock(), _createElementBlock("i", _hoisted_1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_InputText, {
          id: "nick-name-field",
          type: "text",
          class: "name-field",
          modelValue: _ctx.nickName,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.nickName) = $event)),
          placeholder: "Name"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.gender,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.gender) = $event)),
          id: "gender-field",
          options: _ctx.genderOpts,
          optionValue: "key",
          optionLabel: "title",
          placeholder: "Gender",
          class: "gender-field"
        }, null, 8, ["modelValue", "options"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_InputText, {
          id: "dob-date-field",
          type: "date",
          class: "date",
          modelValue: _ctx.dobDate,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.dobDate) = $event)),
          placeholder: "Birth Date"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_InputText, {
          id: "dob-time-field",
          type: "time",
          class: "time",
          modelValue: _ctx.dobTime,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.dobTime) = $event)),
          placeholder: "Birth time"
        }, null, 8, ["modelValue"]),
        _withDirectives(_createVNode(_component_InputText, {
          id: "dob-offset",
          type: "number",
          class: "offset-time",
          modelValue: _ctx.offsetHrs,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.offsetHrs) = $event)),
          placeholder: "Time zone offset",
          step: "0.25"
        }, null, 8, ["modelValue"]), [
          [
            _directive_tooltip,
            _ctx.offsetHoursTip,
            void 0,
            { right: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createVNode(_component_AutoComplete, {
          modelValue: _ctx.placeName,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.placeName) = $event)),
          suggestions: _ctx.suggestedLocations,
          onComplete: _cache[12] || (_cache[12] = ($event: any) => (_ctx.matchPlacename($event))),
          onItemSelect: _ctx.selectPlacename,
          field: "name",
          delay: 500,
          minLength: 2
        }, null, 8, ["modelValue", "suggestions", "onItemSelect"]), [
          [
            _directive_tooltip,
            _ctx.geoDisplay,
            void 0,
            { right: true }
          ]
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["info-trigger", _ctx.locationRowClasses])
        }, [
          _createElementVNode("i", {
            class: "pi pi-compass compass-icon",
            onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.toggleLocation && _ctx.toggleLocation(...args)))
          }),
          (_ctx.hasLocation)
            ? (_openBlock(), _createElementBlock("i", _hoisted_5))
            : _createCommentVNode("", true),
          (_ctx.hasLocation)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "location info-tip",
                onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.toggleLocation && _ctx.toggleLocation(...args)))
              }, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.dmsLocation.lat), 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.dmsLocation.lng), 1),
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.tzInfo), 1)
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.eventType,
          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.eventType) = $event)),
          id: "event-type-field",
          class: "event-type",
          options: _ctx.eventTypes,
          optionValue: "key",
          optionLabel: "name",
          placeholder: "Type of event"
        }, null, 8, ["modelValue", "options"]),
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.roddenValue,
          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.roddenValue) = $event)),
          id: "rodden-value-field",
          class: "rodden-value",
          options: _ctx.roddenOptions,
          optionValue: "key",
          optionLabel: "name",
          placeholder: "Rodden scale"
        }, null, 8, ["modelValue", "options"]),
        (_ctx.isValid)
          ? _withDirectives((_openBlock(), _createElementBlock("i", {
              key: 0,
              class: "pi pi-plus-circle replace-trigger trigger",
              onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.resetForm && _ctx.resetForm(...args)))
            }, null, 512)), [
              [
                _directive_tooltip,
                _ctx.resetTooltip,
                void 0,
                { right: true }
              ]
            ])
          : _createCommentVNode("", true)
      ])
    ], 2)
  ], 64))
}