import { smartCastFloat } from "../converters";

/* export class SimpleGrahaSet {
  su = 0;
  ve = 0;
  ma = 0;

  constructor(inData: any = null) {
    if (inData instanceof Object) {
      Object.entries(inData).forEach(([k, v]) => {
        switch (k) {
          case "su":
          case "ve":
          case "ma":
            this[k] = smartCastFloat(v);
            break;
        }
      });
    }
  }
} */

export class GrahaSet {
  su = -1;
  mo = -1;
  me = -1;
  ve = -1;
  ma = -1;
  ju = -1;
  sa = -1;
  ra = -1;
  ke = -1;
  ur = -1;
  ne = -1;
  pl = -1;
  as = -1;
  ds = -1;

  constructor(inData: any = null) {
    if (inData instanceof Object) {
      Object.entries(inData).forEach(([k, v]) => {
        switch (k) {
          case "su":
          case "mo":
          case "me":
          case "ve":
          case "ma":
          case "ju":
          case "sa":
          case "ra":
          case "ke":
          case "ur":
          case "ne":
          case "pl":
          case "as":
          case "ds":
            this[k] = smartCastFloat(v);
            break;
        }
      });
    }
  }

  lng(key: string): number {
    switch (key) {
      case "su":
      case "mo":
      case "me":
      case "ve":
      case "ma":
      case "ju":
      case "sa":
      case "ra":
      case "ke":
      case "ur":
      case "ne":
      case "pl":
      case "as":
      case "ds":
        return this[key];
      default:
        return -1;
    }
  }
}
