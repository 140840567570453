import axios from "axios";
import { notEmptyString } from "./validators";
import { api } from "../.config";
import { buildOptions } from "./build-headers";
import { User } from "./models/user";
import { fromLocal, toLocal } from "./localstore";
import { GeoLoc } from "./models/geo";
import { LngPairChartData } from "./models/lng-pair-chart-data";
import { defaultLocalSettings } from "./setings";
import { smartCastInt } from "./converters";
import { LocalSettings } from "./interfaces";

const extractDataObj = (res: any) => {
  if (res instanceof Object) {
    const { data } = res;
    if (data instanceof Object || data instanceof Array) {
      return data;
    }
  }
};

const postData = async (
  path: string,
  params: any = null,
  callback: any = null,
  putMode = false
) => {
  let result: any = {};
  const isFormData = params instanceof FormData;
  const func = putMode !== true ? axios.post : axios.put;
  await func(`${api.base}${path}`, params, buildOptions(isFormData, callback))
    .then((res) => {
      result = res;
    })
    .catch((e) => {
      result.error = e;
    });
  return result;
};

/* const putData = async (path: string, params = null, callback: any = null) => {
  return postData(path, params, callback, true);
}; */

const fetchData = async (path: string, deleteMode = false, userId = "") => {
  let result: any = {};
  const func = deleteMode !== true ? axios.get : axios.delete;
  await func(`${api.base}${path}`, buildOptions(false, null, userId))
    .then((res) => {
      result = res;
    })
    .catch((e) => {
      result.error = e;
    });
  return result;
};

const fetchContent = async (path = "", userId = "") => {
  return fetchData(path, false, userId);
};

const deleteItem = async (path = "", userId = "") => {
  return fetchData(path, true, userId);
};

const buildQueryString = (criteria: any = null, literal = false) => {
  let str = "";
  const specialRgx = /[\\/&? ]/;
  if (criteria instanceof Object) {
    const parts: Array<string> = [];
    Object.entries(criteria).forEach((entry) => {
      const [key, val] = entry;
      let paramVal = val;
      if (typeof val === "string") {
        if (!specialRgx.test(val)) {
          literal = true;
        }
        paramVal = literal ? val : encodeURIComponent(val);
      } else if (typeof val === "number" || typeof val === "boolean") {
        paramVal = val.toString();
      } else if (val instanceof Array) {
        paramVal = val.join(",");
      }
      parts.push(key + "=" + paramVal);
    });
    if (parts.length > 0) {
      str = "?" + parts.join("&");
    }
  }
  return str;
};

export const getData = async (path: string) => {
  let data = { valid: false };
  await fetchContent(path).then((response) => {
    if (response.data) {
      data = response.data;
      data.valid = true;
    }
  });
  return data;
};

export const fetchDataObject = async (
  path: string,
  userId = ""
): Promise<any> => {
  let data: any = { valid: false };
  await fetchContent(path, userId).then((res) => {
    const result = extractDataObj(res);
    if (result instanceof Object) {
      data = result;
    }
  });
  return data;
};

export const fetchPreferenceOptions = async (
  survey = "preference_options",
  refresh = false
): Promise<any> => {
  const surveyKey = notEmptyString(survey, 4) ? survey : "preference_options";
  const parts = ["user/preferences", surveyKey];
  if (refresh) {
    parts.push("1");
  }
  const path = parts.join("/");
  return await fetchDataObject(path);
};

export const testFacetedSurveyAnswers = async (
  type = "faceted",
  items: any[] = [],
  refresh = false
): Promise<any> => {
  const parts = ["user", "test-surveys", type];
  if (refresh) {
    parts.push("1");
  }
  if (items instanceof Array && items.length > 0) {
    const response = await postData(parts.join("/"), items);
    if (response instanceof Object) {
      const { data } = response;
      if (data instanceof Object) {
        return { ...data, valid: true };
      }
    }
  }
  return { valid: false };
};

export const fetchPlacenames = async (search: string) => {
  const path = `geo/address/${search}`;
  return await fetchDataObject(path);
};

export const getTzData = async (geo: GeoLoc, dateStr = "") => {
  const parts = ["astrologic", "tzdata", [geo.lat, geo.lng].join(",")];
  if (notEmptyString(dateStr, 5)) {
    parts.push(dateStr);
  }
  const response = await fetchContent(parts.join("/"));
  const { data } = response;
  if (data instanceof Object) {
    return data;
  } else {
    return { valid: false, tzOffset: 0 };
  }
};

export const fetchSubjectData = async () => {
  const url = "setting/person-chart-data";
  const stored = fromLocal(url, 7 * 60 * 60);
  if (!stored.expired) {
    return stored.data;
  } else {
    const response = await fetchContent(url);
    const { data } = response;
    if (data instanceof Object) {
      const result = { ...data, valid: true };
      toLocal(url, result);
      return result;
    } else {
      return { valid: false };
    }
  }
};

export const fetchPublicUser = async (idRef = "") => {
  const url =
    ["user/public-user", idRef, "email"].join("/") +
    buildQueryString({
      p2: 1,
    });
  const response = await fetchContent(url);
  const { data } = response;
  if (data instanceof Object) {
    const result = { ...data, valid: true };
    toLocal("publicuser", result);
    return result;
  } else {
    return { valid: false };
  }
};

export const fetchP2 = async (inData: any = null): Promise<any> => {
  const obj = inData instanceof Object ? inData : {};
  const { geo1, geo2 } = obj;
  const loc1 = geo1 instanceof GeoLoc ? geo1.toString() : "";
  const loc2 = geo2 instanceof GeoLoc ? geo2.toString() : "";
  delete obj.geo1;
  delete obj.geo2;
  const url =
    "astrologic/p2" + buildQueryString({ ...obj, loc1, loc2, extended: 1 });
  const response = await fetchContent(url);
  const { data } = response;
  if (data instanceof Object) {
    const result = { ...data, valid: true };
    return result;
  } else {
    return { valid: false };
  }
};

export const savePublicUser = async (user: User): Promise<any> => {
  const parts = ["user", "public-save"];
  const payload = { ...user };
  const response = await postData(parts.join("/"), payload);
  if (response instanceof Object) {
    const { data } = response;
    if (data instanceof Object) {
      return { ...data, valid: true };
    }
  }
  return { valid: false };
};

export const removePaired = async (uid = "", key = ""): Promise<any> => {
  const uri = ["user", "public-pair-delete", uid, key].join("/");
  const response = await deleteItem(uri, uid);
  const { data } = response;
  return data instanceof Object ? data : { valid: false };
};

export const fetchSetting = async (key: string): Promise<any> => {
  const path = "setting/by-key/" + key;
  return await fetchDataObject(path);
};

export const fetchSessionUser = (): User => {
  const storedUser = fromLocal("publicuser", 7 * 24 * 60 * 60);
  const userData = storedUser.expired ? null : storedUser.data;
  return new User(userData);
};

export const saveLocalSettings = (settings: any = null): boolean => {
  let saved = false;
  if (settings instanceof Object) {
    const newSettings = defaultLocalSettings;
    Object.entries(settings).forEach(([k, v]) => {
      switch (k) {
        case "yearSpan":
        case "futureYears":
          saved = true;
          newSettings[k] = smartCastInt(v);
          break;
      }
    });
    if (saved) {
      toLocal("year-span-settings", newSettings);
    }
  }
  return saved;
};

export const getLocalSettings = (): LocalSettings => {
  const stored = fromLocal("year-span-settings", 7 + 24 * 60 * 60);
  if (!stored.expired && stored.data instanceof Object) {
    return stored.data;
  } else {
    return defaultLocalSettings;
  }
};
